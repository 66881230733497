.history-item {
    padding: 3rem 0 2.5rem 2rem;
    &:first-of-type {
        padding-top: 0;
    }
    &:last-of-type {
        border-bottom: none;
    }
    border: 1px solid var(--border-color);
    border-top: none;
    border-right: none;
    &__date {
        color: var(--text-default-color);
        font-size: 1.2rem;
        line-height: 1rem;
        font-weight: 400;
        margin-bottom: 1.1rem;
        position: relative;
        text-transform: capitalize;
        &::before {
            content: '';
            position: absolute;
            left: calc(-2.5rem);
            width: 0.9rem;
            aspect-ratio: 1;
            border-radius: 50%;
            background-color: var(--highlight-color);
        }
    }
    &__title {
        color: var(--primary-color);
        font-size: 2rem;
        font-weight: 500;
        text-transform: capitalize;
        margin-bottom: 0.5rem;
    }
    &__subtitle {
        font-size: 1.2rem;
        font-weight: 500;
        color: var(--secondary-color);
        margin-bottom: 1rem;
    }
    &__description {
        color: var(--text-default-color);
        font-size: 1.5rem;
        line-height: 2rem;
        li {
            list-style: none;
            margin-top: 1rem;
            span {
                font-weight: 500;
                color: var(--secondary-color);
                text-transform: capitalize;
            }
        }
    }
}
