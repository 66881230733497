.testimonial {
    &::before {
        content: open-quote;
        font-family: 'Roboto', sans-serif;
        font-weight: 900;
        font-size: 4rem;
        color: var(--border-color);
    }
    &::after {
        content: close-quote;
        visibility: hidden;
    }
    &__description {
        color: var(--text-default-color);
        font-size: 2rem;
        font-style: italic;
        line-height: 3.2rem;
        margin-bottom: 1.2rem;
    }
    &__author {
        display: flex;
        gap: 1.2rem;
        align-items: center;
    }
    &__name {
        font-size: 1.7rem;
        font-weight: 500;
        line-height: 2.1rem;
        color: var(--primary-color);
        display: inline;
    }
    &__position {
        font-size: 1.4rem;
        font-weight: 400;
        color: var(--text-default-color);
    }
    &__profile-picture {
        width: 6rem;
        aspect-ratio: 1;
        border-radius: 50%;
    }
    &:hover {
        cursor: pointer;
    }
}

.carousal {
    position: absolute;
    left: 100%;
    width: 100%;
    animation-name: slideToRight;
    animation-iteration-count: infinite;
}
@keyframes slideToRight {
    0% {
        left: 100%;
        visibility: visible;
    }
    15% {
        left: 0%;
        visibility: visible;
    }
    50% {
        left: 0%;
        visibility: visible;
    }
    65% {
        left: -100%;
        visibility: hidden;
    }
    100% {
        left: 100%;
        visibility: hidden;
    }
}
