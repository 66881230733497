:root {
    --primary-color: #011627;
    --hover-color: rgba(1, 22, 39, 0.9);
    --secondary-color: #334452;
    --highlight-color: #e71d36;
    --text-default-color: rgb(80, 80, 80);
    --section-background-color: #fff;
    --progressbar-background-color: #ccd0d3;
    --text-white-color: #f4f4f4;
    --border-color: #ccd0d3;
}
.app-wrapper {
    background-color: var(--secondary-color);
}
.app {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
}
